<template>
	<div class="content">
    <van-dropdown-menu class="tabs" active-color="#FF6450">
      <van-dropdown-item @change="handleFilterChange" v-model="statusFilter" :options="statusOptions" />
      <van-dropdown-item @change="handleFilterChange" v-model="patientFilter" :options="patientOptions" />
    </van-dropdown-menu>
		<div v-if="!dataList.length" class="no-data flex f-jc-c f-ai-c">
			<div class="flex f-d-c f-ai-c">
				<img src="../../assets/images/no-data.png" alt="">
				<span>暂无数据</span>
			</div>
		</div>
		<!-- <div v-else class="wrap"> -->
		<div class="list">
			<div v-for="(item, index) in dataList" :key="index" class="b-w order-item">
				<div class="item-top flex f-ai-c">
          <div class="item-title">
            <img class="doctor-head" :src="`${item.doctorAvatar}`" alt="..." :onerror="defaultImg" />
            <span class="title">{{item.doctorName}} {{item.deptName}}</span>
          </div>
					<span class="status" :style="{ color: statusColor[item.status] }">
						{{
							item.status === 1 ? '待支付' :
							item.status === 2 ? '问诊中' :
							item.status === 3 ? '已完成' :
							item.status === 4 ? '已取消' :
							item.status === 5 ? '已退款' : '未知状态'
						}}
					</span>
				</div>
				<div class="item-middle">
					<div class="middle-item">
            <span>病情描述：</span>
            <div>{{cutdownStr(item.diseaseDescription, 17)}}</div>
          </div>
          <div class="middle-item">
            <span>就诊人：</span>
            <div>{{item.patientName}} {{item.patientGender === 1 ? '男' : '女'}} {{item.age}}岁</div>
          </div>
          <div class="middle-item">
            <span>下单时间：</span>
            <div>{{formatTime(item.createdAt)}}</div>
          </div>
				</div>
				<div class="item-bottom">
          <van-popover
            placement="top-start"
            theme="dark"
            v-model="item.showPopover"
            trigger="click"
            @select="onSelectPop($event, item)"
            :actions="getActions(item)"
          >
            <template #reference>
              <i class="more-icon" v-if="item.status > 2"></i>
            </template>
          </van-popover>
					<!-- <i @click="showItemPopover(index)" class="more-icon" v-if="item.status > 2"></i>
					<div v-if="dataList[index].showPopover">
						<div><span>删除订单</span></div>
						<div><span>评价详情</span></div>
					</div> -->
          <div class="bottom-btns">
            <div @click="cancelHandle(item.consultationId)" v-if="item.status === 1" class="cancel-btn">
              <span>取消</span>
            </div>
            <div @click="toPay(item)" v-if="item.status === 1" class="determine-btn">
              <span>去支付</span>
            </div>
            <div @click="toConversation(item)" v-if="item.status === 2" class="consultation-btn">
              <span>进入问诊</span>
            </div>
            <div @click="toAppraise(item)" v-if="item.status === 3 && !item.appraiseId" class="appraise-btn">
              <span>评价</span>
            </div>
          </div>

				</div>
			</div>
		</div>
		<van-popup v-model="showCancelOrder" class="flex f-ai-c back-dialog">
			<h1>提示</h1>
			<span>是否确认取消该订单？</span>
			<div class="back-btns">
				<span @click="closeCancelDialog(1)">确定</span>
				<span @click="closeCancelDialog()">我再想想</span>
			</div>
		</van-popup>
		<van-popup v-model="showDeleteOrder" class="flex f-ai-c back-dialog">
			<h1>提示</h1>
			<span>是否确认删除该订单？</span>
			<div class="back-btns">
				<span @click="closeDeleteDialog(1)">确定</span>
				<span @click="closeDeleteDialog()">我再想想</span>
			</div>
		</van-popup>
		<!-- </div> -->
	</div>
</template>
<script>
import { consultationDetail, consultationList, patientList, consultationCancel, consultationDelete } from '@/request/api/user';
import { dateFormat } from '@/utils/time';
import { cutdownString } from '@/utils';
export default {
	name: 'consultationIndex',
	data () {
		return {
			activeTab: 0,
			defaultImg: 'this.src="' + require('@/assets/images/doctor-def.png') + '"',
			showPopover: false,
			statusFilter: '',
			patientFilter: '',
			statusOptions: [
				{ text: '全部状态', value: '' },
				{ text: '待支付', value: 1 },
				{ text: '问诊中', value: 2 },
				{ text: '已完成', value: 3 },
				{ text: '已取消', value: 4 },
				{ text: '已退款', value: 5 }
			],
			patientOptions: [
				{ text: '全部就诊人', value: '' }
			],
			dataList: [],
			statusColor: ['', '#F33D3A', '#09BB07', '#FF6450', '#BEBEBE'],
			selOrderId: '',
			showCancelOrder: false,
			showDeleteOrder: false
		};
	},
	created () {
		this.getPatientList();
		this.getConsultationList();
	},
	mounted () {
		if (window.history && window.history.pushState) {
			// history.pushState(null, null, document.URL);
			// window.addEventListener('popstate', this.onBack, false);
		}
		window.addEventListener('popstate', this.onBack, false);
	},
	destroyed () {
		window.removeEventListener('popstate', this.onBack, false);
	},
	methods: {
		onBack () {
			// history.back();
			// window.location.replace(window.origin + '/user-center');
			this.$root.go('/user-center');
		},
		cutdownStr (str, length) {
			return cutdownString(str, length);
		},
		handleFilterChange () {
			this.getConsultationList();
		},
		formatTime (time) {
			return dateFormat(new Date(time), 'yyyy.MM.dd hh:mm');
		},
		getActions (item) {
			if (item.status === 4 || item.status === 5 || (item.status === 3 && !item.appraiseId)) return [{ text: '删除订单', type: 1 }];
			if (item.status === 3 && item.appraiseId) return [{ text: '删除订单', type: 1 }, { text: '评价详情', type: 2 }];
		},
		onSelectPop (action, item) {
			// console.log('action=======', action, item);
			if (action.type === 1) {
				this.selOrderId = item.consultationId;
				this.showDeleteOrder = true;
			}
			if (action.type === 2) this.toAppraise(item);
		},
		closeDeleteDialog (type) {
			if (type) {
				consultationDelete({
					consultationId: this.selOrderId
				}).then(data => {
					if (data && data === 'retry') {
						this.closeDeleteDialog(type);
					} else if (data && data.ok === 1) {
						this.$toast('删除订单成功');
						this.getConsultationList();
					}
				});
			}
			this.showDeleteOrder = false;
		},
		hidePopover () {
			const array = [...this.dataList];
			for (let i = 0; i < array.length; i++) {
				array[i].showPopover = false;
			}
			this.dataList = [...array];
		},
		showItemPopover (index) {
			// console.log('this.dataList====', index)
			// item.showPopover = true;
			const array = [...this.dataList];
			for (let i = 0; i < array.length; i++) {
				if (i === index) {
					array[i].showPopover = true;
				} else {
					array[i].showPopover = false;
				}
			}
			this.dataList = [...array];
		},
		getPatientList () {
			patientList().then(data => {
				if (data && data === 'retry') {
					this.getPatientList();
				} else if (data) {
					// console.log('data=====', data);
					for (const item of data) {
						this.patientOptions.push({ text: item.name, value: item.id });
					}
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		},
		getConsultationList () {
			this.dataList = [];
			consultationList({
				status: this.statusFilter,
				patientId: this.patientFilter
			}).then(data => {
				if (data && data === 'retry') {
					this.getConsultationList();
				} else if (data) {
					this.dataList = [...data];
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		},
		toPay (item) {
			// this.$root.go('/appointment-detail', { id });
			// this.$toast('去支付');
			consultationDetail({ doctorId: item.doctorId, patientId: item.patientId, id: item.consultationId }).then(data => {
				if (data && data === 'retry') {
					this.toPay(item);
				} else if (data) {
					// console.log('consultationDetail====', data);
					let qa = {};
					if (data.consultationAnswer) {
						qa = JSON.parse(data.consultationAnswer);
					}
					this.$store.commit('setStep', {
						step: 'stepTwo',
						obj: { qa: qa, consultationType: data.consultationType }
					});
					this.$store.commit('setStep', {
						step: 'stepConsultation',
						obj: { consultation: data }
					});
					this.$root.go('/consultation-pay', {
						doctorId: data.doctorId,
						patientId: data.patientId,
						subType: data.type,
						id: data.id,
						doctorName: item.doctorName,
						departmentName: item.deptName,
						departmentId: data.departmentId,
						isList: 1
					});
				}
			});
		},
		closeCancelDialog (type) {
			if (type) {
				consultationCancel({
					consultationId: this.selOrderId
				}).then(data => {
					if (data && data === 'retry') {
						this.closeCancelDialog(type);
					} else if (data && data.ok === 1) {
						this.$toast('取消订单成功');
						this.getConsultationList();
					}
				});
			}
			this.showCancelOrder = false;
		},
		cancelHandle (id) {
			this.selOrderId = id;
			this.showCancelOrder = true;
		},
		toConversation (item) {
			this.$root.go('/dip', { doctorId: item.doctorId, at: 2, conversationId: item.conversationId, patientId: item.patientId });
		},
		toAppraise (item) {
			// console.log('item=======', item);
			const query = { consultationId: item.consultationId, doctorId: item.doctorId, doctorName: item.doctorName, patientId: item.patientId, deptName: item.deptName, titleName: item.titleName, doctorAvatar: item.doctorAvatar };
			if (item.appraiseId) query.appraiseId = item.appraiseId;
			this.$root.go('/appraise', query);
			// this.$toast('评价');
		}
	}
};
</script>
<style lang="less">
 .van-popover__action {
   width: auto;
   padding-top: 7px;
   padding-bottom: 7px;
   height: auto;
 }
 .van-popover--dark .van-popover__content {
   background-color: rgba(0, 0, 0, .7);
   border-radius: 4px;
   padding: 5px 0;
 }
 .van-popover__action-text {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 12px;
 }
</style>
<style lang="less" scoped>
	.content {
		// padding-top: 46px;
		display: flex;
		flex-direction: column;
		overflow: hidden;
    height: 100%;
  }
  .tabs {
		height: 46px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #7D7D7D;
		padding: 0;
		// position: fixed;
    // top: 0;
    width: 100%;
		z-index: 1;
	}
	.no-data {
		height: 300px;
		img {
			width: 100px;
			height: 100px;
			margin: 0 auto;
		}
		span {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #8B8B8B;
		}
	}
	.list {
		// height: 200px;
		flex: 1;
		overflow: auto;
		padding-bottom: 12px;
	}
	.order-item {
		padding: 0 15px;
		padding-bottom: 10px;
		margin-top: 12px;
		// height: 161px;
		box-shadow: 0px 4.5px 18px -4px rgba(16, 31, 46, 0.04);
		border-radius: 4px;
	}
	.item-top {
		// height: 39px;
		// box-sizing: border-box;
    // position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F3F3F3;
    padding: 14px 0;
    .item-title {
      display: flex;
      align-items: center;
      .doctor-head {
        width: 31px;
        height: 31px;
        border-radius: 100%;
        background-color: #909090;
      }
      .title {
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #232830;
        line-height: 13px;
        margin-left: 10px;
      }
    }
		.status {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 13px;
		}
	}
	.item-middle {
    display: flex;
    flex-direction: column;
    padding: 11px 0;
    border-bottom: 1px solid #F3F3F3;
    .middle-item {
      margin-top: 8px;
			display: flex;
			white-space: nowrap;
      span:first-child {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7D7D7D;
        line-height: 13px;
        width: 67px;
      }
      div {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3B3D40;
        line-height: 13px;
      }
    }
	}
	.item-bottom {
    margin-top: 10px;
    display: flex;
    align-items: center;
		justify-content: space-between;
		position: relative;
    .more-icon {
      position: relative;
      height: 29px;
      width: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
		.more-icon::before {
      content: '\2026';
      display: block;
      color: #959595;
      font-size: 20px;
      position: absolute;
      top: -5px;
    }
    .bottom-btns {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      div {
        padding: 8px 18px;
				border-radius: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
      }
      span {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 13px;
        // width: 54px;
        text-align: center;
      }
      .cancel-btn {
        background: #FFFFFF;
        border: 1px solid #BEBEBE;
				margin-right: 10px;
				padding: 7px 25px;
        span {
          color: #3B3D40;
        }
			}
			.consultation-btn,
      .determine-btn {
				border: none;
				margin-right: 1px;
        background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
        span {
          color: #FFFFFF;
        }
			}
			.consultation-btn {
				padding: 8px 12px;
			}
      .appraise-btn {
        background: #FFFFFF;
				border: 1px solid #FF6450;
				padding: 7px 25px;
				margin-right: 1px;
        span {
          color: #FF6450;
        }
      }
    }
	}
	.back-dialog {
		flex-direction: column;
		padding: 30px 25px 25px 25px;
		width: 290px;
		height: 120px;
		background: #FFFFFF;
		border-radius: 7px;
		justify-content: space-between;
		h1 {
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #232830;
		}
		span {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #3B3D40;
			text-align: center;
		}
		.back-btns {
			span {
				display: inline-block;
				width: 112px;
				height: 33px;
				background: #FFFFFF;
				border-radius: 16.5px;
				border: 1px solid #FF6450;
				line-height: 33px;
				margin-top: 20px;
				&:nth-child(1) {
					color: #FF6450;
					margin-right: 16px;
				}
				&:nth-child(2) {
					color: #FFFFFF;
					background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
				}
			}
		}
	}
</style>