var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "van-dropdown-menu",
        { staticClass: "tabs", attrs: { "active-color": "#FF6450" } },
        [
          _c("van-dropdown-item", {
            attrs: { options: _vm.statusOptions },
            on: { change: _vm.handleFilterChange },
            model: {
              value: _vm.statusFilter,
              callback: function ($$v) {
                _vm.statusFilter = $$v
              },
              expression: "statusFilter",
            },
          }),
          _c("van-dropdown-item", {
            attrs: { options: _vm.patientOptions },
            on: { change: _vm.handleFilterChange },
            model: {
              value: _vm.patientFilter,
              callback: function ($$v) {
                _vm.patientFilter = $$v
              },
              expression: "patientFilter",
            },
          }),
        ],
        1
      ),
      !_vm.dataList.length
        ? _c("div", { staticClass: "no-data flex f-jc-c f-ai-c" }, [_vm._m(0)])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "list" },
        _vm._l(_vm.dataList, function (item, index) {
          return _c("div", { key: index, staticClass: "b-w order-item" }, [
            _c("div", { staticClass: "item-top flex f-ai-c" }, [
              _c("div", { staticClass: "item-title" }, [
                _c("img", {
                  staticClass: "doctor-head",
                  attrs: {
                    src: "" + item.doctorAvatar,
                    alt: "...",
                    onerror: _vm.defaultImg,
                  },
                }),
                _c("span", { staticClass: "title" }, [
                  _vm._v(_vm._s(item.doctorName) + " " + _vm._s(item.deptName)),
                ]),
              ]),
              _c(
                "span",
                {
                  staticClass: "status",
                  style: { color: _vm.statusColor[item.status] },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        item.status === 1
                          ? "待支付"
                          : item.status === 2
                          ? "问诊中"
                          : item.status === 3
                          ? "已完成"
                          : item.status === 4
                          ? "已取消"
                          : item.status === 5
                          ? "已退款"
                          : "未知状态"
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "item-middle" }, [
              _c("div", { staticClass: "middle-item" }, [
                _c("span", [_vm._v("病情描述：")]),
                _c("div", [
                  _vm._v(_vm._s(_vm.cutdownStr(item.diseaseDescription, 17))),
                ]),
              ]),
              _c("div", { staticClass: "middle-item" }, [
                _c("span", [_vm._v("就诊人：")]),
                _c("div", [
                  _vm._v(
                    _vm._s(item.patientName) +
                      " " +
                      _vm._s(item.patientGender === 1 ? "男" : "女") +
                      " " +
                      _vm._s(item.age) +
                      "岁"
                  ),
                ]),
              ]),
              _c("div", { staticClass: "middle-item" }, [
                _c("span", [_vm._v("下单时间：")]),
                _c("div", [_vm._v(_vm._s(_vm.formatTime(item.createdAt)))]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "item-bottom" },
              [
                _c("van-popover", {
                  attrs: {
                    placement: "top-start",
                    theme: "dark",
                    trigger: "click",
                    actions: _vm.getActions(item),
                  },
                  on: {
                    select: function ($event) {
                      return _vm.onSelectPop($event, item)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "reference",
                        fn: function () {
                          return [
                            item.status > 2
                              ? _c("i", { staticClass: "more-icon" })
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: item.showPopover,
                    callback: function ($$v) {
                      _vm.$set(item, "showPopover", $$v)
                    },
                    expression: "item.showPopover",
                  },
                }),
                _c("div", { staticClass: "bottom-btns" }, [
                  item.status === 1
                    ? _c(
                        "div",
                        {
                          staticClass: "cancel-btn",
                          on: {
                            click: function ($event) {
                              return _vm.cancelHandle(item.consultationId)
                            },
                          },
                        },
                        [_c("span", [_vm._v("取消")])]
                      )
                    : _vm._e(),
                  item.status === 1
                    ? _c(
                        "div",
                        {
                          staticClass: "determine-btn",
                          on: {
                            click: function ($event) {
                              return _vm.toPay(item)
                            },
                          },
                        },
                        [_c("span", [_vm._v("去支付")])]
                      )
                    : _vm._e(),
                  item.status === 2
                    ? _c(
                        "div",
                        {
                          staticClass: "consultation-btn",
                          on: {
                            click: function ($event) {
                              return _vm.toConversation(item)
                            },
                          },
                        },
                        [_c("span", [_vm._v("进入问诊")])]
                      )
                    : _vm._e(),
                  item.status === 3 && !item.appraiseId
                    ? _c(
                        "div",
                        {
                          staticClass: "appraise-btn",
                          on: {
                            click: function ($event) {
                              return _vm.toAppraise(item)
                            },
                          },
                        },
                        [_c("span", [_vm._v("评价")])]
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ])
        }),
        0
      ),
      _c(
        "van-popup",
        {
          staticClass: "flex f-ai-c back-dialog",
          model: {
            value: _vm.showCancelOrder,
            callback: function ($$v) {
              _vm.showCancelOrder = $$v
            },
            expression: "showCancelOrder",
          },
        },
        [
          _c("h1", [_vm._v("提示")]),
          _c("span", [_vm._v("是否确认取消该订单？")]),
          _c("div", { staticClass: "back-btns" }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.closeCancelDialog(1)
                  },
                },
              },
              [_vm._v("确定")]
            ),
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.closeCancelDialog()
                  },
                },
              },
              [_vm._v("我再想想")]
            ),
          ]),
        ]
      ),
      _c(
        "van-popup",
        {
          staticClass: "flex f-ai-c back-dialog",
          model: {
            value: _vm.showDeleteOrder,
            callback: function ($$v) {
              _vm.showDeleteOrder = $$v
            },
            expression: "showDeleteOrder",
          },
        },
        [
          _c("h1", [_vm._v("提示")]),
          _c("span", [_vm._v("是否确认删除该订单？")]),
          _c("div", { staticClass: "back-btns" }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.closeDeleteDialog(1)
                  },
                },
              },
              [_vm._v("确定")]
            ),
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.closeDeleteDialog()
                  },
                },
              },
              [_vm._v("我再想想")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex f-d-c f-ai-c" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/no-data.png"), alt: "" },
      }),
      _c("span", [_vm._v("暂无数据")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }